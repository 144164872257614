import some from 'lodash/some';

import { ApplicationCode } from 'types/app';
import { routes } from 'types/pages';
import { GranularPermissions, IdentifierTypes, User } from 'types/user';
import { verifyGranularPermissions } from 'utility/helper/helperFunctions';

//These methods should be called only on client side
export const CanViewCharts = (user: User) => {
  return (
    verifyGranularPermissions(user, [GranularPermissions.BATCHES]) &&
    user.activeCustomer?.idType !== IdentifierTypes.CLIENT_ID
  );
};

export const CanViewAddMembersCard = (user: User) => {
  if (!user.callToActions.addNewUser) {
    try {
      sessionStorage.removeItem(`${user.activeCustomer?.idValue}_memberCardDismissed`);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    verifyGranularPermissions(user, [GranularPermissions.MANAGE_USERS]) &&
    user.flags.showCTACard &&
    user.callToActions.addNewUser
  );
};

export const CanOrderEquipment = (user: User) => {
  if (verifyGranularPermissions(user, [GranularPermissions.ORDER_EQUIPMENT])) return true;
  else return false;
};

export const CanAccessBatchesAndTransactions = (user: User) => {
  return (
    user.isVerified &&
    user.activeCustomer?.idType == IdentifierTypes.MERCHANT_ID &&
    verifyGranularPermissions(user, [GranularPermissions.BATCHES])
  );
};

//These methods should be called for route permission checks, primarily used server-side.
export const RestrictedRoutes = {
  [routes.home]: {
    isAuthorized: () => true,
    inSideNav: () => true,
  },
  [routes.restaurant]: {
    isAuthorized: () => true,
    inSideNav: () => true,
  },
  [routes.retail]: {
    isAuthorized: () => true,
    inSideNav: () => true,
  },
  [routes.documents]: {
    isAuthorized: (user: User) => {
      if (user) {
        return (
          user.isVerified &&
          user.userHasActivePaymentsAccounts &&
          verifyGranularPermissions(user, [GranularPermissions.MANAGE_USERS])
        );
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.isVerified && user.userHasActivePaymentsAccounts;
      }
      return false;
    },
  },
  [routes.userManagement]: {
    isAuthorized: (user: User) => {
      if (user) {
        return user.isVerified && user.userHasActivePaymentsAccounts;
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.isVerified && user.userHasActivePaymentsAccounts;
      }
      return false;
    },
  },
  [routes.checkout]: {
    isAuthorized: (user: User) => {
      if (user) {
        if (
          user.activeCustomerInformation.isAddToCartAllowed &&
          verifyGranularPermissions(user, [GranularPermissions.ORDER_EQUIPMENT])
        ) {
          return true;
        }
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        if (user.isVerified) {
          return true;
        }
      }
      return false;
    },
  },
  [routes.equipment]: {
    isAuthorized: (user: User) => {
      if (user) {
        return user.isVerified;
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.isVerified;
      }
      return false;
    },
  },
  [routes.equipmentDetails]: {
    isAuthorized: (user: User) => {
      if (user) {
        return user.isVerified;
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.isVerified;
      }
      return false;
    },
  },
  [routes.paymentsPlus]: {
    isAuthorized: (user: User) => {
      return !!user;
    },
    inSideNav: (user: User) => !!user,
  },
  [routes.payrollPlus]: {
    isAuthorized: (user: User) => {
      if (user) {
        return some(user.assignedCustomerApplications, (x) => x.code === ApplicationCode.Payroll);
      }
      return false;
    },
    inSideNav: (user: User) => !!user,
  },
  [routes.benefitsAdministration]: {
    isAuthorized: (user: User) => {
      if (user) {
        return some(user.assignedCustomerApplications, (x) => x.code === ApplicationCode.PayrollBenefitsAdministration);
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showBenefitsAdministration;
      }
      return false;
    },
  },
  [routes.hiringAndOnboarding]: {
    isAuthorized: (user: User) => {
      if (user) {
        return some(user.assignedCustomerApplications, (x) => x.code === ApplicationCode.PayrollHiringAndOnboarding);
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showHiringAndOnBoarding;
      }
      return false;
    },
  },
  [routes.sgaSimulator]: {
    isAuthorized: (user: User) => {
      return user.flags.showSGASimulator;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showSGASimulator;
      }
      return false;
    },
  },
  [routes.permissionOverride]: {
    isAuthorized: (user: User) => {
      return user.flags.showPermissionOverride;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showPermissionOverride;
      }
      return false;
    },
  },
  [routes.timeAndAttendance]: {
    isAuthorized: (user: User) => {
      if (user) {
        return some(user.assignedCustomerApplications, (x) => x.code === ApplicationCode.PayrollTimeAndAttendance);
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showTimeAndAttendance;
      }
      return false;
    },
  },
  [routes.loans]: {
    isAuthorized: (user: User) => !!user,
    inSideNav: (user: User) => !!user,
  },
  [routes.store]: {
    isAuthorized: (user: User) => {
      if (user) {
        return user.flags.showIntegrations;
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showIntegrations;
      }
      return false;
    },
  },
  [routes.billPay]: {
    isAuthorized: (user: User) => {
      if (user) {
        return some(
          user.assignedCustomerApplications,
          (x) =>
            x.code === ApplicationCode.BillPayMerchantView &&
            (x.navLinkUrl.includes('hlprd.com') && x.navLinkUrl.includes(routes.billPay)) === false,
        );
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showBillPay;
      }
      return false;
    },
  },
  [routes.inPerson]: {
    isAuthorized: (user: User) => !!user,
    inSideNav: (user: User) => !!user,
  },
  [routes.pointOfSale]: {
    isAuthorized: (user: User) => !!user,
    inSideNav: (user: User) => !!user,
  },
  [routes.reports]: {
    isAuthorized: (user: User) => {
      if (user) {
        if (user.activeCustomer?.idType == IdentifierTypes.MERCHANT_ID || user.userHasActivePaymentsAccounts)
          return true;
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        if (user.isVerified) {
          if (user.activeCustomer?.idType == IdentifierTypes.MERCHANT_ID || user.userHasActivePaymentsAccounts) {
            return true;
          }
        }
      }
      return false;
    },
  },
  [routes.ach]: {
    isAuthorized: (user: User) => {
      return true;
    },
    inSideNav: (user: User) => {
      // TODO Verify that flags are correct
      return false;
    },
  },
  [routes.accountSettings]: {
    isAuthorized: (user: User) => {
      if (user) {
        return (
          user.flags.showAccountSettings && verifyGranularPermissions(user, [GranularPermissions.VIEW_ACCOUNT_SETTINGS])
        );
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return (
          user.flags.showAccountSettings && verifyGranularPermissions(user, [GranularPermissions.VIEW_ACCOUNT_SETTINGS])
        );
      }
      return false;
    },
  },
  [routes.support]: {
    isAuthorized: () => true,
    inSideNav: () => true,
  },
  [routes.batchesAndTransactions]: {
    isAuthorized: (user: User) => {
      if (user) {
        return CanAccessBatchesAndTransactions(user);
      }
      return false;
    },
    inSideNav: () => false,
  },
  [routes.impersonateUser]: {
    isAuthorized: (user: User) => {
      if (user.impersonateMode) return true;

      if (user) {
        return user.flags.showImpersonateUser;
      }
      return false;
    },
    inSideNav: (user: User) => {
      if (user) {
        return user.flags.showImpersonateUser;
      }
      return false;
    },
  },
  [routes.funding]: {
    isAuthorized: (user: User) => {
      if (user) {
        return user.flags.showFundingReport && verifyGranularPermissions(user, [GranularPermissions.FUNDING]);
      }
      return false;
    },
    inSideNav: () => false,
  },
  [routes.statementReport]: {
    isAuthorized: (user: User) => {
      //-- ToDo: [Menisck] check LD flag here
      if (user) {
        return (
          user.flags.showStatementReport && verifyGranularPermissions(user, [GranularPermissions.MERCHANT_STATEMENTS])
        );
      }
      return false;
    },
    inSideNav: () => false,
  },
};
